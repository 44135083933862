var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pa-5 content__base",
      class: [_vm.color ? "active" : "deActive"],
    },
    [
      _c("div", { attrs: { id: "buttonBody" } }, [
        _vm.title
          ? _c("div", { staticClass: "mb-3" }, [
              _c("b", { staticClass: "text-capitalize text-wrap" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ])
          : _vm._e(),
        !_vm.color
          ? _c("div", { staticClass: "d-flex flex-row" }, [
              _c("span", { staticClass: "text-capitalize text-wrap" }, [
                _c("strong", [_vm._v("NOME: ")]),
                _vm._v(" " + _vm._s(_vm.text) + " "),
              ]),
            ])
          : _c("div", { staticClass: "d-flex flex-row" }, [
              _c("span", { staticClass: "text-capitalize text-wrap" }, [
                _vm._v(" " + _vm._s(_vm.text)),
              ]),
            ]),
        !_vm.color
          ? _c("div", { staticClass: "d-flex flex-row" }, [
              _c("span", { staticClass: "text-capitalize text-wrap" }, [
                _c("strong", [_vm._v("VIDAS: ")]),
                _vm._v(" " + _vm._s((_vm.vidas.length || 0) + 1) + " "),
              ]),
            ])
          : _vm._e(),
        !_vm.color
          ? _c("div", { staticClass: "d-flex flex-row" }, [
              _c("span", { staticClass: "text-capitalize text-wrap" }, [
                _c("strong", [_vm._v("STATUS: ")]),
                _vm._v(
                  " " + _vm._s(_vm._f("etapaAdesaoPme")(_vm.status)) + " "
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { attrs: { id: "bottom" } }, [_vm._v("Acessar")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }