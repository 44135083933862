<template>
  <div :class="[color ? 'active' : 'deActive']" class="pa-5 content__base">
    <div id="buttonBody">
      <div v-if="title" class="mb-3">
        <b class="text-capitalize text-wrap">{{ title }}</b>
      </div>
      <div class="d-flex flex-row" v-if="!color">
        <span class="text-capitalize text-wrap"> <strong>NOME: </strong> {{ text }} </span>
      </div>
      <div class="d-flex flex-row" v-else>
        <span class="text-capitalize text-wrap"> {{ text }}</span>
      </div>
      <div class="d-flex flex-row" v-if="!color">
        <span class="text-capitalize text-wrap"> <strong>VIDAS: </strong> {{ (vidas.length || 0) + 1 }} </span>
      </div>
      <div class="d-flex flex-row" v-if="!color">
        <span class="text-capitalize text-wrap"> <strong>STATUS: </strong> {{ status | etapaAdesaoPme }} </span>
      </div>
    </div>
    <div id="bottom">Acessar</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
    },
    color: {
      type: Boolean,
      default: false,
    },
    // Ajuste erros no console prop 'vidas'
    vidas: {},
    count: {
      type: String,
      required: false,
    },
    count: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: false,
      required: false,
    },
    // Ajuste erros no console prop 'status'
    status: {},
    guidProposta: {
      default: "",
    },
    warning: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
.content__base {
  height: 115%;
  font-size: 16px;
  width: 100%;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  border-radius: 12px;
  font-size: 4 !important;
}
#buttonBody {
  flex-grow: 1;
}
#bottom {
  font-size: 16px;
  display: inline-block;
  text-align: right;
}
.active {
  background-color: #171a88;
  color: #ffffff;
}
.deActive {
  background-color: #eeeeee;
  color: #000000;
}
</style>
