import api from "@/services/api";
import AppError from "@/utils/appError";

const resource = "empresas";
const resourcePropostas = "propostas";

const incluirEmpresa = async (body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.post(`${resource}`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarEmpresa = async (id, body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.put(`${resource}/${id}`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarOperadora = async ({ id, operadora }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.put(`${resource}/${id}/operadora`, {
      operadora,
    }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarVigencia = async ({ id, vigencia, dataFechamentoAceitacao, dataFechamentoReapresentacao }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.put(`${resource}/${id}/vigencia`, {
      vigencia, dataFechamentoAceitacao, dataFechamentoReapresentacao
    }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarDadosFinanceiros = async ({id, body}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.put(`${resource}/${id}/financeiro`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const get = async() => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };

      const { data } = await api.post(`${resource}/buscar/todas`, {}, config);
      const list = data && data.data && data.data.propostasPME ? data.data.propostasPME : [];

      return list;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
}

const getByGuid = async(guid) => {
  try {
      const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
      const { data } = await api.get(`${resource}/${guid}`, config);
      const list = data && data.data ? data.data: [];

      return list;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
}

const buscarInformacoesTelasCadastro = async(guid) => {
  try {
      const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
      const { data } = await api.get(`${resource}/${guid}/informacoes-telas-cadastro`, config);

      return data.data;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
}

const buscarDadosPropostasAdesao = async(guid) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PROPOSTA_API_KEY } };
    const { data } = await api.post(`${resourcePropostas}/adicionaDados/${guid}`, {}, config);
    return data.data[0];
  } catch (error) {
    console.log(error)
    const { response } = error;
    throw new AppError(response, 400);
  }
}

const getByCnpj = async(cnpj) => {
  try {
      const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
      const { data } = await api.get(`${resource}/cnpj/${cnpj}`, config);

      const list = data && data.data ? data.data: [];
      return list;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
}

const atualizarEtapa = async ({id, etapa}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.put(`${resource}/${id}/etapa`, {
      etapa,
    }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
}

const buscarDadosCNPJZooxSmart = async (cnpj) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.get(`${resource}/zoox-smart/${cnpj}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
}

export default {
  incluirEmpresa,
  atualizarOperadora,
  atualizarVigencia,
  atualizarDadosFinanceiros,
  incluirEmpresa,
  atualizarEmpresa,
  atualizarEtapa,
  get,
  getByGuid,
  getByCnpj,
  buscarInformacoesTelasCadastro,
  buscarDadosCNPJZooxSmart,
  buscarDadosPropostasAdesao,
};
